<template>
  <div class="awardBookDetail_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <ol class="list_box">
      <li class="list" v-for="(item, index) in list" :key="index">
        <img class="photo" :src="item.imgUrl" alt="" @click="goImagePreview(index)">
      </li>
    </ol>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import { ImagePreview } from 'vant';
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader
  },
  data() {
    return {
      title: this.$route.meta.title,
      list: []
    }
  },
  created() {
    this.onLoad()
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    async onLoad() {
      const res = await this.$HTTP.get(this, this.$API['API_PRIZE_BOOK_ONE'], { pb_id: this.$route.query.key }, {}, true)
      this.list = res.data.listDetails
    },
    goImagePreview(index) {
      ImagePreview({ images: this.list.map(item => { return item.imgUrl }), startPosition: index })
    }
  }
};
</script>

<style lang="less">
.awardBookDetail_page {
  .list_box {
    display: flex;
    flex-wrap: wrap;
    .list {
      width: 42.5%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      margin: 5% 0 0 5%;
      overflow: hidden;
      .photo {
        width: 100%;
        display: block;
        border-radius: 2px;
        overflow: hidden;
      }
    }
  }
}
</style>